import React from "react";
import { useSelector } from "react-redux";
import { useRouter } from "next/dist/client/router";
import { Base64 } from "js-base64";
import { LocalizationProvider } from "@fluent/react";

import KButton from "@kikoff/components/src/v1/buttons/KButton";

import UnderlineTextInput from "@component/inputs/underline_text_input";
import { EIGHTY_PERCENT_OFF, FIRST_MONTH_FREE } from "@constant/promos";
import { Promo, selectIntroPromo } from "@feature/user";
import { sputter } from "@util/analytics";
import { l10nResources } from "@util/l10n";

import styles from "./styles.module.scss";

const l10n = l10nResources({
  en: `landing-open-acct-in-mins = Open your account in minutes!
landing-get-started = Get started
landing-your-email-placeholder = Your email
promo-disclaimer = Special offer applies to new Kikoff members only. Sign up is subject to approval & identity verification. Receive $5 off the first month of the basic or premium Kikoff plan. After the first month, members will be charged the normal Kikoff monthly rate. Offer is not available on mobile app. Terms of offer are subject to change.
promo-disclaimer-eighty = Special offer applies to new Kikoff members only. Sign up is subject to approval & identity verification. Receive 80% off the first month of the basic or premium Kikoff plan. After the first month, members will be charged the normal Kikoff monthly rate. Offer is not available on mobile app. Terms of offer are subject to change.
promo-disclaimer-free = Special offer applies to new Kikoff members only. Sign up is subject to approval & identity verification. Receive $5 off the first month of the basic Kikoff plan or $20 off the first month of the premium Kikoff plan. After the first month, members will be charged the normal Kikoff monthly rate. Offer is not available on mobile app. Terms of offer are subject to change.
cta-get-first-month-free = Get First Month for FREE*
cta-eighty-percent-off = Get First Month 80% off*
`,
  es: `landing-open-acct-in-mins = ¡Abre tu cuenta en minutos!
landing-get-started = Empezar
landing-your-email-placeholder = Tu correo electrónico
promo-disclaimer = La oferta especial se aplica únicamente a los nuevos miembros de Kikoff. El registro está sujeto a aprobación y verificación de identidad. Reciba $5 de descuento el primer mes del plan Kikoff básico o premium. Después del primer mes, a los miembros se les cobrará la tarifa mensual normal de Kikoff. La oferta no está disponible en la aplicación móvil. Los términos de la oferta están sujetos a cambios.
promo-disclaimer-eighty = La oferta especial se aplica únicamente a los nuevos miembros de Kikoff. El registro está sujeto a aprobación y verificación de identidad. Reciba 80% de descuento el primer mes del plan Kikoff básico o premium. Después del primer mes, a los miembros se les cobrará la tarifa mensual normal de Kikoff. La oferta no está disponible en la aplicación móvil. Los términos de la oferta están sujetos a cambios.
promo-disclaimer-free = La oferta especial se aplica únicamente a los nuevos miembros de Kikoff. El registro está sujeto a aprobación y verificación de identidad. Reciba $5 de descuento el primer mes del plan Kikoff básico o $20 de descuento el primer mes del plan Kikoff premium. Después del primer mes, a los miembros se les cobrará la tarifa mensual normal de Kikoff. La oferta no está disponible en la aplicación móvil. Los términos de la oferta están sujetos a cambios.
cta-get-first-month-free = Obtenga el primer mes GRATIS*
cta-eighty-percent-off = Obtenga el primer mes 80% de descuento*

`,
});

function PromoDisclaimer({ promo }: { promo: Promo }) {
  let disclaimerText = l10n.getString("promo-disclaimer");
  if (promo.name === EIGHTY_PERCENT_OFF) {
    disclaimerText = l10n.getString("promo-disclaimer-eighty");
  } else if (promo.name === FIRST_MONTH_FREE) {
    disclaimerText = l10n.getString("promo-disclaimer-free");
  }

  return (
    <div
      className={`color:moderate-on-dugout text:mini ${styles["promo-disclaimer"]}`}
    >
      *{disclaimerText}
    </div>
  );
}

// NOTE: the sticky footer appears when this component has gone out of view. Use
//   sticky-footer-anchor data-attribute to dictate when sticky footer appears.
//   It is moved off the email input, because marketing/design are removing input.
const MainCta: React.FC = () => {
  const router = useRouter();

  const introPromo = useSelector(selectIntroPromo());

  const getButtonText = () => {
    if (introPromo) {
      return l10n.getString(
        introPromo?.name === EIGHTY_PERCENT_OFF
          ? "cta-eighty-percent-off"
          : "cta-get-first-month-free"
      );
    }
    return l10n.getString("landing-get-started");
  };

  return (
    <form
      className={styles["main-cta"]}
      data-cy="main-cta-form"
      onSubmit={(e) => {
        e.preventDefault();
        sputter("signup: submitted landing page email", {
          section: "main cta",
        });

        const encodedSignupParam = Base64.encodeURI(
          JSON.stringify({
            email: new FormData(e.currentTarget).get("email"),
            no_confirm: true,
          })
        );

        router.push(`/onboarding?signup_data=${encodedSignupParam}`);
      }}
    >
      <LocalizationProvider l10n={l10n}>
        <UnderlineTextInput
          className={styles["new-input"]}
          label={l10n.getString("landing-your-email-placeholder")}
          name="email"
        />
        <div className={styles["button-wrapper"]}>
          <KButton
            className={styles["get-started-button"]}
            data-sticky-footer-anchor
            size="full-width"
            type="submit"
            variant="primary"
          >
            <span className="color:white text:large+">{getButtonText()}</span>
          </KButton>
        </div>
        {introPromo && <PromoDisclaimer promo={introPromo} />}
      </LocalizationProvider>
    </form>
  );
};

export default MainCta;
